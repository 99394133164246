.metrics-plot-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.metrics-plot-container .plot-controls {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}

.metrics-plot-container .plot-controls .inline-control {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.metrics-plot-container .plot-controls .inline-control .control-label {
  margin-right: 10px;
}

.metrics-plot-container .plot-controls .block-control {
  margin-top: 25px;
}

.metrics-plot-container .metrics-plot-data {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.metrics-plot-container .metrics-plot-view-container {
  min-height: 500px;
  flex: 1;
}

.metrics-plot-container .metrics-summary {
  margin: 20px 20px 20px 60px;
}

.metrics-plot-container .metrics-summary .html-table-view {
  margin-bottom: 25px;
  /* Shrink to fit, so that scroll bars are aligned with the edge of the table */
  display: inline-block;
}

/* Reset min-width which is overridden in HtmlTableView as this breaks the
   table layout when scrolling is enabled and widths are specified */
.metrics-plot-container .metrics-summary .html-table-view th {
  min-width: auto;
}
