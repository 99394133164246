.model-version-update-form .ant-form-item {
    margin-bottom: 0;
}

.model-version-update-form .ant-form-item-label {
    width: 100%;
    text-align: left;
}

.model-version-update-form div[role="status"][class^="css-"] {
    margin-right: -0.5px;
}
