div.preview-outer-container {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10%;
}

span.preview-header {
  font-size: 24px;
  display: block;
}

span.preview-supported-formats {
  font-size: 16px;
  padding-top: 8px;
  color: #888888;
  display: block;
}

span.preview-max-size {
  font-size: 16px;
  padding-top: 8px;
  color: #888888;
  display: block;
}

div.preview-image-container {
  height: 54px;
  width: 52px;
  margin-right: 24px;
}

img.preview-image {
  height: 54px;
  width: 52px;
}

div.preview-text {
  vertical-align: middle;
}

.artifact-info {
  white-space: nowrap;
}
