.outer-container {
  display: flex;
}
.HomePage-experiment-list-container {
  flex: 0 0 312px;
}
.experiment-view-container {
  /*
   * Allow experiment view container to be smaller than its content (i.e. a large-width runs table)
   * by overriding min-width as described in https://stackoverflow.com/a/38383437 and
   * https://developer.mozilla.org/en-US/docs/Web/CSS/flex
   */
  min-width: 0px;
  flex: 1 1;
  padding: 0 64px 0 32px;
}


/* BEGIN css for when experiment list collapsed */
.expander {
  display: inline-block;
  background-color: #082142d6;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}
/* END css for when experiment list collapsed */
